import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import notifyReducer from './Redux/notifySlice';
import authReducer from './Redux/authSlice';
import studentReducer from './Redux/studentsSlice';
import batchReducer from './Redux/batchSlice';
import subjectReducer from './Redux/subjectSlice';
import topicReducer from './Redux/topicSlice';
import dialogReducer from './Redux/dialogSlice';
import testReducer from './Redux/testSlice';
import studentdetailReducer from './Redux/studentDetailSlice';
import submissionReducer from './Redux/submissionSlice';
import testdetailReducer from './Redux/testdetailSlice';
import dashboardReducer from './Redux/dashboardSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist:['auth','studentdetail']
}
const rootReducer = combineReducers({
  auth: authReducer,
  notify: notifyReducer,
  dashboard: dashboardReducer,
  students:studentReducer,
  batch:batchReducer,
  subject:subjectReducer,
  topic:topicReducer,
  dialog:dialogReducer,
  test:testReducer,
  studentdetail:studentdetailReducer,
  submission:submissionReducer,
  testdetail:testdetailReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});
export const persistor = persistStore(store)
export default store;