import amber from '@material-ui/core/colors/amber'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Login from './app/Login'
import Main from './app/Main'
import ViewPDF from './app/ViewPDF'
import ViewStudent from './app/ViewStudent'
import ViewSubmission from './app/ViewSubmission'
import ViewTest from './app/ViewTest'
import Notifier from './utils/Notifier'
function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#333333",
        main: "#000000",
        dark: "#000000",
      },
      secondary: amber,
    },
  })
  const auth = useSelector(state => state.auth)
  const AuthorizedRoute = ({ component: Component, ...routeProps }) =>
    (
      <Route {...routeProps} render={(props) => auth.token ? (<Component {...props} />) : (<Redirect to="/" />)} />
    );
  return (
    <ThemeProvider theme={theme}>
      <Notifier />
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <AuthorizedRoute path="/student/:id" component={ViewStudent}/>
          <AuthorizedRoute path="/submission/:id" component={ViewSubmission}/>
          <AuthorizedRoute path="/test/:id" component={ViewTest}/>
          <AuthorizedRoute path="/pdf/:id" component={ViewPDF}/>
          <AuthorizedRoute path="/" component={Main} />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App


