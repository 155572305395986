import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';

export const getBatches = createAsyncThunk(
    'batch/getAll',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/batches')
        return response.data
    }
)
export const addBatch = createAsyncThunk(
    'batch/addBatch',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post('/batches', data.body)
        return response.data
    }
)
export const updateBatch = createAsyncThunk(
    'batch/updateBatch',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).put('/batches', data.body)
        return response.data
    }
)
export const deleteBatch = createAsyncThunk(
    'batch/deleteBatch',
    async (data, thunkAPI) => {
        await makeRequest(data.token, null, thunkAPI).delete(`/batches/${data.id}`)
        return data.id
    }
)

export const getBatchTopics = createAsyncThunk(
    'batch/getBBatchTopics',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get(`/batches/topics/${data.batch_id}/${data.subject_id}`)
        return response.data
    }
)
export const syncBatchTopics = createAsyncThunk(
    'batch/syncBatchTopics',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post(`/batches/topics`, data.body)
        return response.data
    }
)

const batchSlice = createSlice({
    name: 'batch',
    initialState: {
        data: [],
        loading: false,
        subject_topics: [],
        batch_topics: []
    },
    reducers: {
        clearTopics: (state) => {
            state.subject_topics = []
            state.batch_topics = []
        }
    },
    extraReducers: {
        [getBatches.fulfilled]: (state, action) => {
            state.data = action.payload.data
            state.loading = false
        },
        [getBatches.pending]: (state, action) => {
            state.loading = true
        },
        [getBatches.rejected]: (state, action) => {
            state.loading = false
        },
        [addBatch.fulfilled]: (state, action) => {
            state.data.push(action.payload.data)
            state.loading = false
        },
        [addBatch.pending]: (state, action) => {
            state.loading = true
        },
        [addBatch.rejected]: (state, action) => {
            state.loading = false
        },
        [updateBatch.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.id === action.payload.data.id) {
                    return { ...item, ...action.payload.data }
                }
                return item
            })
            state.loading = false
        },
        [updateBatch.pending]: (state, action) => {
            state.loading = true
        },
        [updateBatch.rejected]: (state, action) => {
            state.loading = false
        },
        [deleteBatch.fulfilled]: (state, action) => {
            state.data = state.data.filter(x => x.id !== action.payload)
            state.loading = false
        },
        [deleteBatch.pending]: (state, action) => {
            state.loading = true
        },
        [deleteBatch.rejected]: (state, action) => {
            state.loading = false
        },
        [getBatchTopics.fulfilled]: (state, action) => {
            state.batch_topics = action.payload.data.batch_topics
            state.subject_topics = action.payload.data.topics
            state.loading = false
        },
        [getBatchTopics.pending]: (state, action) => {
            state.loading = true
        },
        [getBatchTopics.rejected]: (state, action) => {
            state.loading = false
        },
        [syncBatchTopics.fulfilled]: (state, action) => {
            state.batch_topics = action.payload.data
            state.loading = false
        },
        [syncBatchTopics.pending]: (state, action) => {
            state.loading = true
        },
        [syncBatchTopics.rejected]: (state, action) => {
            state.loading = false
        },
    }
});
export const { clearTopics } = batchSlice.actions;
export default batchSlice.reducer;
