import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';

export const getTopics = createAsyncThunk(
    'topic/getAll',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/topics')
        return response.data
    }
)
export const addTopic = createAsyncThunk(
    'topic/addTopic',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post('/topics', data.body)
        return response.data
    }
)
export const updateTopic = createAsyncThunk(
    'topic/updateTopic',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).put('/topics', data.body)
        return response.data
    }
)
export const deleteTopic = createAsyncThunk(
    'topic/deleteTopic',
    async (data, thunkAPI) => {
        await makeRequest(data.token, null, thunkAPI).delete(`/topics/${data.id}`)
        return data.id
    }
)
const topicSlice = createSlice({
    name: 'topic',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {

    },
    extraReducers: {
        [getTopics.fulfilled]: (state, action) => {
            state.data = action.payload.data
            state.loading = false
        },
        [getTopics.pending]: (state, action) => {
            state.loading = true
        },
        [getTopics.rejected]: (state, action) => {
            state.loading = false
        },
        [addTopic.fulfilled]: (state, action) => {
            state.data.push(action.payload.data)
            state.loading = false
        },
        [addTopic.pending]: (state, action) => {
            state.loading = true
        },
        [addTopic.rejected]: (state, action) => {
            state.loading = false
        },
        [updateTopic.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.id === action.payload.data.id) {
                    return { ...item, ...action.payload.data}
                }
                return item
            })
            state.loading = false
        },
        [updateTopic.pending]: (state, action) => {
            state.loading = true
        },
        [updateTopic.rejected]: (state, action) => {
            state.loading = false
        },
        [deleteTopic.fulfilled]: (state, action) => {
            state.data = state.data.filter(x => x.id !== action.payload)
            state.loading = false
        },
        [deleteTopic.pending]: (state, action) => {
            state.loading = true
        },
        [deleteTopic.rejected]: (state, action) => {
            state.loading = false
        },
    }
});
export default topicSlice.reducer;
