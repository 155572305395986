import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from './Components/DataTable'
import { getSubjects } from './Redux/subjectSlice'
import { addTopic, deleteTopic, getTopics, updateTopic } from './Redux/topicSlice'
function Topics() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const topic = useSelector(state => state.topic)
    const subject = useSelector(state => state.subject)
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: "never" },
        { title: 'Name', field: 'name' },
        { title: 'Subject', field: 'subject_id' },
        { title: "Grade", field: "subject.grade",type:"numeric" }
    ])
    useEffect(() => {
        if (subject.data.length === 0) {
            dispatch(getSubjects({ token: auth.token }))
        }
        dispatch(getTopics({ token: auth.token }))
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        setData(JSON.parse(JSON.stringify(topic.data)))
    }, [topic.data])
    useEffect(() => {
        if (subject.data.length !== 0) {
            let data = JSON.parse(JSON.stringify(subject.data))
            let lookup = {}
            data.forEach(element => {
                lookup[element.id] = `${element.name} (Grade-${element?.grade})`
            });
            let newColumns = columns.map(item => {
                if (item.field === "subject_id") {
                    return { ...item, lookup: lookup }
                }
                return item
            })
            setColumns(newColumns)
        }
        // eslint-disable-next-line  
    }, [subject.data])
    return (
        <DataTable
            title={"Topics"}
            columns={columns}
            loading={topic.loading}
            data={data}
            onAdd={newData => dispatch(addTopic({
                token: auth.token,
                body: newData
            }))}
            onUpdate={(newData, oldData) => dispatch(updateTopic({
                token: auth.token,
                body: newData
            }))}
            onDelete={(oldData) => dispatch(deleteTopic({
                token: auth.token,
                id: oldData.id
            }))}
        />
    )
}

export default Topics
