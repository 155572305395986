import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTestData } from './Redux/testdetailSlice'
import { MathfieldComponent } from 'react-mathlive'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import LiveTv from '@material-ui/icons/LiveTvRounded'
function ViewTest(props) {
    const auth = useSelector(state => state.auth)
    const testdetail = useSelector(state => state.testdetail)
    const [questions, setQuestions] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTestData({
            token: auth.token,
            test_id: props.match.params.id
        }))
        // eslint-disable-next-line
    }, [props.match.params.id])
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    useEffect(() => {
        if (testdetail.test_data) {
            const url = "https://api.selectschoolmelbourne.com.au/test/";
            let q = []
            let i = 1
            testdetail.test_data.questions.forEach(element => {
                q.push(
                    <Grid item key={element.id}>
                        {element.intro_text != null && <div className="m-3 " dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img className="m-3 " src={url + element.intro_image} alt={`intro_image${element.id}`} />}
                        <Card raised >
                            <CardHeader title={`Q${i}`}>
                            </CardHeader>
                            <CardContent>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={url + element.question_image} alt={`question_image${element.id}`} />}
                                <br />
                                {element.mcq === 1 ?
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={element.id + ""} name={String(element.id)} >
                                            {JSON.parse(element.options).map((option, index) => (
                                                option.type === "string" ?
                                                    <FormControlLabel
                                                        key={`Option${element.id}${index + 1}`}
                                                        value={String(index + 1)}
                                                        control={<Radio />}
                                                        label={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value}
                                                    />
                                                    :
                                                    <FormControlLabel
                                                        key={`Option${element.id}${index + 1}`}
                                                        value={String(index + 1)}
                                                        control={<Radio />}
                                                        label={
                                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <div>{alphabets[index]}</div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <img src={url + option.value} alt={`Option${index + 1}`} />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    :
                                    <div style={{ border: "1px solid black" }} key={`M${element.id}`}>
                                        <MathfieldComponent
                                            // latex={latex}
                                            mathfieldConfig={{
                                                virtualKeyboardMode: "onfocus",
                                                virtualKeyboardTheme: "material",
                                                editingOptions: {
                                                    ignoreSpacebarInMathMode: true,
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                )
                i++
            });
            setQuestions(q)
        }
        // eslint-disable-next-line
    }, [testdetail.test_data])
    return (
        <Container style={{ marginTop: 100 }}>
            <Grid container direction="column" spacing={2}>
                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        <Typography variant={"h4"}>{testdetail.test_data?.name}</Typography>
                    </Grid>
                    {testdetail.test_data?.tutorial_link ?
                        <Grid item>
                            <Button
                                href={testdetail.test_data.tutorial_link}
                                target="_blank"
                                variant="contained"
                                color="primary"
                                startIcon={<LiveTv />}>
                                Watch Tutorial</Button>
                        </Grid>
                        :
                        null
                    }
                </Grid>
                {questions}
            </Grid>
        </Container>
    )
}

export default ViewTest
