import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import React, { useEffect } from 'react'
import { getTopics } from '../Redux/topicSlice';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
export default function TopicSelect({ data }) {
    const topic = useSelector(state => state.topic)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        if (topic.data.length === 0) {
            dispatch(getTopics({
                token: auth.token
            }))
        }
        // eslint-disable-next-line
    }, [])
    return (
        <List component="nav" >
            {topic.data.map(item => (
                <ListItem key={`topic${item.id}`} button onClick={() => { data.onClick(item.id) }}>
                    <ListItemText primary={item.name} />
                </ListItem>
            ))}
        </List>
    )
}
