import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getSubmissionData = createAsyncThunk(
    'submission/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get(`/student/test/${data.test_id}/${data.student_id}`)
        return response.data
    }
)
const submissionSlice = createSlice({
    name: "submission",
    initialState: {
        loading: false,
        test_data: null,
        user_data: null,
    },
    reducers: {
        reset:(state,action)=>{
            state.test_data = null
            state.user_data = null
        }
    },
    extraReducers: {
        [getSubmissionData.fulfilled]: (state, action) => {
            state.loading = false
            state.test_data = action.payload.data.test_data[0]
            state.user_data = action.payload.data.user_data[0]
        },
        [getSubmissionData.rejected]: (state, action) => {
            state.loading = false
        },
        [getSubmissionData.pending]: (state, action) => {
            state.loading = true
        },
    }
})
export default submissionSlice.reducer