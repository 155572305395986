import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { addTest } from "../Redux/testSlice";
import { Box, Dialog, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AddTest({ open, onClose }) {
  const topic = useSelector((state) => state.topic);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    final_test: false,
    active: false,
  });
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <Box p={2}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              required
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <Autocomplete
              id="combo-box-topics"
              options={topic.data}
              getOptionLabel={(option) =>
                `${option.name} (Grade-${option.subject?.grade})`
              }
              onChange={(event, value) =>
                setData({ ...data, topic_id: value?.id })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Topic"
                  variant="outlined"
                />
              )}
            />
            {/* <TextField name="subject_id" label="Subject" fullWidth variant="outlined" required select onChange={handleChange} size="small">
                            {topic.data.map(item => (
                                <MenuItem key={`option${item.id}`} value={item.id}>
                                    {item.name} (Grade-{item.subject.grade})
                                </MenuItem>
                            ))}
                        </TextField> */}
          </Grid>
          <Grid item>
            <FormControlLabel
              name="final_test"
              value={data.final_test}
              control={<Checkbox color="primary" />}
              label="Topic Test"
              labelPlacement="right"
              onChange={(event, checked) => {
                setData({ ...data, final_test: checked });
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              name="active"
              value={data.active}
              control={<Checkbox color="primary" />}
              label="Active"
              labelPlacement="right"
              onChange={(event, checked) => {
                setData({ ...data, active: checked });
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="time_limit"
              label="Time limit"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <TextField
              name="tutorial_link"
              label="Tutorial link"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(
                  addTest({
                    token: auth.token,
                    body: data,
                  })
                );
                onClose();
              }}
            >
              Add Test
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
