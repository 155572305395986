import React, { useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopics } from "./Redux/topicSlice";
import { deleteTest, getTests, updateTest } from "./Redux/testSlice";
import { Link, useHistory } from "react-router-dom";
import AddTest from "./Components/AddTest";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import View from "@material-ui/icons/VisibilityRounded";
import UploadQuestions from "./Components/UploadQuestions";
import MaterialTable from "material-table";
import { SERVER_URL } from "../utils/constants";
import TestToPDF from "./Components/TestToPDF";
import { Box } from "@material-ui/core";

function Tests() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const topic = useSelector((state) => state.topic);
  const test = useSelector((state) => state.test);
  const [add, setAdd] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    // { title: 'ID', field: 'id', editable: "never" },
    { title: "Name", field: "name" },
    { title: "Topic", field: "topic_name" },
    { title: "Subject", field: "subject_name" },
    { title: "Grade", field: "grade", type: "numeric" },
    { title: "Topic Test", field: "final_test", type: "boolean" },
    { title: "Active", field: "active", type: "boolean" },
    { title: "Time Limit", field: "time_limit", type: "numeric" },
    // { title: 'Tutorial Link', field: 'tutorial_link' ,width:20},
  ]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    View: forwardRef((props, ref) => <View {...props} ref={ref} />),
  };
  useEffect(() => {
    if (topic.data.length === 0) {
      dispatch(getTopics({ token: auth.token }));
    }
    dispatch(getTests({ token: auth.token }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (topic.data.length !== 0) {
      let testData = JSON.parse(JSON.stringify(test.data));
      let topicData = JSON.parse(JSON.stringify(topic.data));
      let finalData = [];
      testData.forEach((element) => {
        let topic = topicData.find((x) => x.id === element.topic_id);
        if (topic && topic.subject) {
          finalData.push({
            ...element,
            topic_name:topic.name,
            subject_name: topic.subject.name,
            grade: topic.subject.grade,
          });
        }
      });
      setData(finalData);
    } else {
      setData(JSON.parse(JSON.stringify(test.data)));
    }
  }, [test.data, topic.data]);
  
  function openAdd() {
    setAdd(true);
  }
  function closeAdd() {
    setAdd(false);
  }
  return (
    <>
      <AddTest open={add} onClose={closeAdd} />
      <MaterialTable
        icons={tableIcons}
        title={"Tests"}
        columns={columns}
        data={data}
        loading={topic.loading}
        actions={[
          {
            icon: tableIcons.Add,
            tooltip: "Add",
            isFreeAction: true,
            onClick: openAdd,
          },
          {
            icon: tableIcons.View,
            tooltip: "View",
            onClick: (event, rowData) => {
              history.push(`/test/${rowData.id}`);
            },
          },
          {
            icon: tableIcons.Export,
            tooltip: "Download",
            onClick: async (event, rowData) => {
              let data = new FormData();
              data.append("id", rowData.id);
              const response = await fetch(`${SERVER_URL}/downloadExcel`, {
                body: data,
                method: "POST",
                headers: {
                  authorization: auth.token,
                },
              });
              const responseJson = await response.json();
              window.location.href = responseJson.data;
            },
          },
        ]}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          filtering: true,
          pageSize: 25,
          pageSizeOptions: [25, 50, 75, 100],
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            dispatch(
              updateTest({
                token: auth.token,
                body: newData,
              })
            ),
          onRowDelete: (oldData) =>
            dispatch(
              deleteTest({
                token: auth.token,
                id: oldData.id,
              })
            ),
        }}
        detailPanel={(rowData) => (
          <Box m={2}>
            <UploadQuestions rowData={rowData} />
            <Box mt={2}/>
            <Link to={`/pdf/${rowData.id}`}>View</Link>
            {/* <TestToPDF id={rowData.id} name={rowData.name} token={auth.token} /> */}
          </Box>
        )}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  );
}

export default Tests;
