import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { dialogClose } from '../Redux/dialogSlice';
import { ADD_STUDENT_DIALOG, SELECT_BATCH_TOPIC } from '../../utils/constants';
import AddStudent from './AddStudent';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded'
import TopicSelect from './TopicSelect';
const useStyles = makeStyles({
    closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
        color: "#9e9e9e"
    }
})
export default function CustomDialog(props) {
    const classes = useStyles()
    const dialog = useSelector(state => state.dialog)
    const dispatch = useDispatch(props)
    return (
        <Dialog open={dialog.open} onClose={() => dispatch(dialogClose())} fullWidth maxWidth={"sm"}>
            <DialogTitle id="form-dialog-title">{dialog.title}</DialogTitle>
            <IconButton className={classes.closeBtn} onClick={() => dispatch(dialogClose())}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {dialog.component === ADD_STUDENT_DIALOG ? <AddStudent /> : null}
                {dialog.component === SELECT_BATCH_TOPIC ? <TopicSelect data={dialog.data} /> : null}
            </DialogContent>
        </Dialog>
    );
}

