import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjects } from '../Redux/subjectSlice';
import { clearTopics, getBatches, getBatchTopics, syncBatchTopics } from '../Redux/batchSlice';
import TopicTransferList from '../Components/TopicTransferList'
function AssignToBatch() {
    const auth = useSelector(state => state.auth)
    const batch = useSelector(state => state.batch)
    const subject = useSelector(state => state.subject)
    const dispatch = useDispatch()
    const [selected, setSelected] = useState()
    const [selectedSubject, setSelectedSubject] = useState()
    const [a, setA] = useState([])
    const [na, setNa] = useState([])
    useEffect(() => {
        if (batch.data.length === 0) {
            dispatch(getBatches({
                token: auth.token
            }))
        }
        if (subject.data.length === 0) {
            dispatch(getSubjects({
                token: auth.token
            }))
        }
        return () => {
            dispatch(clearTopics())
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (selected && selectedSubject) {
            dispatch(getBatchTopics({
                token: auth.token,
                batch_id: selected.id,
                subject_id: selectedSubject.id
            }))
        }
        // eslint-disable-next-line
    }, [selected, selectedSubject])
    useEffect(() => {
        let a = JSON.parse(JSON.stringify(batch.batch_topics))
        let na = JSON.parse(JSON.stringify(batch.subject_topics))
        let newNA = []
        na.forEach(element => {
            let found = a.find(x => x.id === element.id)
            if (found) {
                return
            }
            newNA.push(element)
        });
        setNa(newNA)
        setA(a)
    }, [batch.subject_topics, batch.batch_topics])
    const onAdd = (assigned) => {
        let ids = assigned.map(item => item.id)
        dispatch(syncBatchTopics({
            token: auth.token,
            body: {
                topic_ids: ids,
                batch_id: selected.id,
                subject_id: selectedSubject.id,
                operation: "add"
            }
        }))
    }
    const onRemove = (assigned) => {
        let ids = assigned.map(item => item.id)
        dispatch(syncBatchTopics({
            token: auth.token,
            body: {
                topic_ids: ids,
                batch_id: selected.id,
                subject_id: selectedSubject.id,
                operation: "remove"
            }
        }))
    }
    return (
        <Grid container spacing={4} direction="column">
            <Grid container item spacing={2} justify="center">
                <Grid item>
                    <Autocomplete
                        id="combo-box-students"
                        options={batch.data}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setSelected(value)}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Search Batch" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="combo-box-subjects"
                        options={subject.data}
                        getOptionLabel={(option) => `${option.name} (Grade-${option.grade})`}
                        onChange={(event, value) => setSelectedSubject(value)}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Search Subject" variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <TopicTransferList notAssigned={na} assigned={a} onAdd={onAdd} onRemove={onRemove} />
        </Grid>
    )
}

export default AssignToBatch
