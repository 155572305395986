import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { addStudent } from '../Redux/studentsSlice'

export default function AddStudent() {
    const batch = useSelector(state => state.batch)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [data, setData] = useState({
        active: 1,
        trial: 0
    })
    const handleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }
    
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <TextField name="name" label="Name" fullWidth variant="outlined" required onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="username" label="Username" fullWidth variant="outlined" required onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="email" label="Email" fullWidth variant="outlined" type="email" onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="mobile_number" label="Mobile Number" fullWidth variant="outlined" type="number" onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="grade" label="Grade" fullWidth variant="outlined" required type="number" onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="batch_id" label="Batch" fullWidth variant="outlined" required select onChange={handleChange} size="small">
                    {batch.data.map(item => (
                        <MenuItem key={`option${item.id}`} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <TextField name="password" label="Password" fullWidth variant="outlined" required type="password" onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="password_confirmation" label="Confirm Password" fullWidth variant="outlined" required type="password" onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="birthdate" label="Birthdate" fullWidth variant="outlined" type="date" InputLabelProps={{ shrink: true }} onChange={handleChange} size="small" />
            </Grid>
            <Grid item>
                <TextField name="school" label="School" fullWidth variant="outlined" onChange={handleChange} size="small" />
            </Grid>
            {/* <Grid item>
                <TextField name="subjects" label="Subjects" fullWidth variant="outlined" onChange={handleChange} />
            </Grid> */}
            <Grid item>
                <TextField name="address" label="Address" fullWidth variant="outlined" multiline rowsMax={4} onChange={handleChange} size="small" />
            </Grid>
            <Grid item >
                <FormControlLabel
                    value="top"
                    control={<Switch color="secondary" value={data.active === 1 ? true : false} onChange={(event) => { setData({ ...data, active: event.target.checked ? 1 : 0 }) }} />}
                    label="Active"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value="top"
                    control={<Switch color="secondary" value={data.trial === 1 ? true : false} onChange={(event) => { setData({ ...data, trial: event.target.checked ? 1 : 0 }) }} />}
                    label="Trial"
                    labelPlacement="top"
                />
            </Grid>
            <Grid item >
                <Button variant="contained" color="primary" fullWidth onClick={() => {
                    dispatch(addStudent({
                        token: auth.token,
                        body: data
                    }))
                }}>
                    Add Student
                </Button>
            </Grid>
        </Grid>
    )
}
