import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getStudentDetails = createAsyncThunk(
    'studentdetails/getData',
    async (data,thunkAPI)=>{
        const response =await makeRequest(data.token,null,thunkAPI).get(`/student/detail/${data.student_id}`)
        return response.data
    }
)
export const getSubjectData = createAsyncThunk(
    'subject/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post(`/student/subject`,data.body)
        return {
            subject_id: data.body.subject_id,
            response: response.data
        }
    }
)
const studentDetailSlice = createSlice({
    name:"studentdetail",
    initialState:{
        loading:false,
        student:null,
        subject_data:{},
        test_data:[],
        user_data:[]
    },
    extraReducers:{
        [getStudentDetails.fulfilled]:(state,action)=>{
            state.loading=false
            state.student=action.payload.data[0]
        },
        [getStudentDetails.rejected]:(state,action)=>{
            state.loading=false
        },
        [getStudentDetails.pending]:(state,action)=>{
            state.loading=true
        },
        [getSubjectData.fulfilled]: (state, action) => {
            state.loading = false
            state.subject_data = {...state.subject_data, [action.payload.subject_id]: action.payload.response.data}
        },
        [getSubjectData.pending]: (state, action) => {
            state.loading = true
        },
        [getSubjectData.rejected]: (state, action) => {
            state.loading = false
        }
    }
})
export default studentDetailSlice.reducer