import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubmissionData } from './Redux/submissionSlice'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutlineRounded';
import Cancel from '@material-ui/icons/CancelRounded';
import { MathfieldComponent } from 'react-mathlive'
import Container from '@material-ui/core/Container'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUncheckedRounded';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import LiveTv from '@material-ui/icons/LiveTvRounded'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/styles/makeStyles'
const rightStyles = makeStyles({
    root: {
        backgroundColor: "#4caf50"
    }
})
function ViewSubmission(props) {
    const classes = rightStyles()
    const auth = useSelector(state => state.auth)
    const submission = useSelector(state => state.submission)
    const studentdetail = useSelector(state => state.studentdetail)
    const [questions, setQuestions] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSubmissionData({
            token: auth.token,
            test_id: props.match.params.id,
            student_id: studentdetail.student.id
        }))
        // eslint-disable-next-line
    }, [props.match.params.id])
    let alphabets = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. ', 'G. ', 'H. ', 'I. ', 'J. ', 'K. ', 'L. ', 'M. ', 'N. ', 'O. ', 'P. ', 'Q. ', 'R. ', 'S. ', 'T. ', 'U. ', 'V. ', 'W. ', 'X. ', 'Y. ', 'Z .']
    useEffect(() => {
        if (submission.test_data && submission.user_data) {
            let q = []
            let i = 1
            let answers = JSON.parse(submission.user_data.test_data)
            submission.test_data.questions.forEach(element => {
                let c = ""
                if (element.answer === answers[element.id]) {
                    c = "correct"
                } else {
                    if (answers[element.id]) {
                        c = "incorrect"
                    } else {
                        c = "noanswer"
                    }
                }
                q.push(
                    <Grid item>
                        {element.intro_text != null && <div dangerouslySetInnerHTML={{ __html: element.intro_text }}></div>}
                        {element.intro_image != null && <img src={process.env.PUBLIC_URL + element.intro_image} alt={`intro_image${element.id}`} />}
                        <Card raised>
                            <CardHeader title={`Q${i}`} subheader={c === "noanswer" && <p>(No Answer)</p>} />
                            <CardContent>
                                {element.question != null && <div className="question" dangerouslySetInnerHTML={{ __html: element.question }}></div>}
                                {element.question_image != null && <img src={process.env.PUBLIC_URL + element.question_image} alt={`question_image${element.id}`} />}
                                <br />
                                {element.mcq === 1 ?
                                    <List>
                                        {JSON.parse(element.options).map((option, index) => (
                                            option.type === "string" ?
                                                <Box maxWidth="50%">
                                                    {/* eslint-disable-next-line  */}
                                                    <ListItem selected={answers[element.id] == index + 1 && c !== "correct"} classes={(element.answer == index + 1) && (c === "correct") ? classes : null}>
                                                        <ListItemIcon>
                                                            <RadioButtonUnchecked />
                                                        </ListItemIcon>
                                                        <ListItemText primary={alphabets.includes(option.value + ". ") ? option.value : alphabets[index] + option.value} />
                                                        {/* eslint-disable-next-line  */}
                                                        {element.answer == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <CheckCircleOutline color="secondary" />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null
                                                        }
                                                        {/* eslint-disable-next-line  */}
                                                        {c === "incorrect" && answers[element.id] == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <Cancel />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null}
                                                    </ListItem>
                                                </Box>

                                                :
                                                <Box maxWidth="50%">
                                                    {/* eslint-disable-next-line  */}
                                                    <ListItem selected={answers[element.id] == index + 1 && c !== "correct"} classes={(element.answer == index + 1) && (c === "correct") ? classes : null}>
                                                        <ListItemIcon>
                                                            <RadioButtonUnchecked />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <div>{alphabets[index]}</div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <img src={process.env.PUBLIC_URL + option.value} alt={`Option${index + 1}`} />
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                        {/* eslint-disable-next-line  */}
                                                        {element.answer == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <CheckCircleOutline color="secondary" />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null
                                                        }
                                                        {/* eslint-disable-next-line  */}
                                                        {c === "incorrect" && answers[element.id] == index + 1 ?
                                                            <ListItemSecondaryAction>
                                                                <Cancel />
                                                            </ListItemSecondaryAction>
                                                            :
                                                            null}
                                                    </ListItem>
                                                </Box>

                                        ))}
                                    </List>
                                    :
                                    <div>
                                        <div style={{ flexDirection: "row", width: "50%" }} key={element.id}>
                                            <div style={{ width: "30%", display: "inline-block" }}>
                                                <MathfieldComponent
                                                    latex={answers[element.id]}
                                                    mathfieldConfig={{
                                                        readOnly: true,
                                                        editingOptions: {
                                                            ignoreSpacebarInMathMode: true,
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {/* eslint-disable-next-line  */}
                                            {element.answer == answers[element.id] && <i className="fa fa-check" style={{ color: "#4caf50", marginLeft: 10 }} />}
                                            {/* eslint-disable-next-line  */}
                                            {(c == "incorrect" && answers[element.id] == answers[element.id]) && <i className="fa fa-times" style={{ color: "#aa2e25", marginLeft: 10 }} />}
                                        </div>
                                        {/* eslint-disable-next-line  */}
                                        {(c == "incorrect" || c == "noanswer") ?
                                            <div style={{ flexDirection: "row", width: "50%" }} key={element.id}>
                                                <strong>Correct answer:</strong>
                                                <div style={{ width: "30%" }}>
                                                    <MathfieldComponent
                                                        latex={element.answer}
                                                        mathfieldConfig={{
                                                            readOnly: true,
                                                            editingOptions: {
                                                                ignoreSpacebarInMathMode: true,
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                )
                i++
            });
            setQuestions(q)

        }
        // eslint-disable-next-line 
    }, [submission.test_data])
    return (
        <Container style={{ marginTop: 100 }}>
            <Grid container spacing={2} direction="column">
                <Grid container item alignItems="center" direction="column">
                    <Grid item>
                        <Typography variant={"h4"}>{submission.test_data?.name}</Typography>
                    </Grid>
                    {submission.test_data?.tutorial_link ?
                        <Grid item>
                            <Button
                                href={submission.test_data.tutorial_link}
                                target="_blank"
                                variant="contained"
                                color="primary"
                                startIcon={<LiveTv />}>
                                Watch Tutorial</Button>
                        </Grid>
                        :
                        null
                    }
                </Grid>
                {questions}
            </Grid>
        </Container>
    )
}

export default ViewSubmission
