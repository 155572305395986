import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStudentDetails } from './Redux/studentDetailSlice'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { getSubjects } from './Redux/subjectSlice'
import StudentSubject from './Components/StudentSubject'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function ViewStudent(props) {
    const classes = useStyles();
    const auth = useSelector(state => state.auth)
    const subject = useSelector(state => state.subject)
    const dispatch = useDispatch()
    const studentdetail = useSelector(state => state.studentdetail)
    const [value, setValue] = React.useState(0);
    useEffect(() => {
        if (subject.data.length === 0) {
            dispatch(getSubjects({
                token: auth.token
            }))
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        dispatch(getStudentDetails({
            token: auth.token,
            student_id: props.match.params.id
        }))
        // eslint-disable-next-line
    }, [props.match.params.id])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container >
            {studentdetail.student ?
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Card raised>
                            <CardHeader title={studentdetail.student.name} subheader={studentdetail.student.email} />
                            <CardContent>
                                <div className={classes.root}>
                                    <AppBar position="static">
                                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                            {subject.data.map(item => (
                                                <Tab label={item.name} {...a11yProps(item.id)} />
                                            ))}
                                        </Tabs>
                                    </AppBar>
                                    {subject.data.map((item, index) => (
                                        <TabPanel value={value} index={index}>
                                            <StudentSubject id={item.id} />
                                        </TabPanel>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                :
                null
            }
        </Container>
    )
}

export default ViewStudent
