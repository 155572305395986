import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubjectData } from '../Redux/studentDetailSlice'
import AssignmentIcon from '@material-ui/icons/AssignmentRounded'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PlayCircle from '@material-ui/icons/PlayCircleFilledRounded';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
import Ballot from '@material-ui/icons/BallotRounded';
import DoneAll from '@material-ui/icons/DoneAll';
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
function StudentSubject({ id }) {
    const auth = useSelector(state => state.auth)
    const studentdetail = useSelector(state => state.studentdetail)
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])
    const getData = () => {
        let subject_id = id
        if (!studentdetail.subject_data.subject_id) {
            dispatch(getSubjectData({
                token: auth.token,
                body: {
                    subject_id: subject_id,
                    user_id: studentdetail.student.id
                }
            }))
        }
    }
    return (
        <Grid container spacing={2}>
            {studentdetail.loading ?
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
                :
                null
            }
            {studentdetail.subject_data[id]?.length === 0 ?
                <Grid item xs={12}>
                    <Paper>
                        <Box p={2}>
                            <Typography variant="body2">No tests available</Typography>
                        </Box>
                    </Paper>
                </Grid>
                :
                null
            }
            {studentdetail.subject_data[id]?.map(item => (
                <Grid item key={item.id} xs={12} sm={6}>
                    <Card>
                        <CardHeader title={item.name} titleTypographyProps={{ variant: "button" }} avatar={<AssignmentIcon />} />
                        <List>
                            {item.tests.map(test => {
                                if (test.final_test === 1) return null
                                return (
                                    <ListItem button component="a" key={test.id} onClick={() => {
                                        if (test.submission) {
                                            history.push(`/submission/${test.id}`)
                                        }
                                    }}>
                                        <ListItemIcon>
                                            {test.submission ? <CheckCircle color={"secondary"} /> : <PlayCircle />}
                                        </ListItemIcon>
                                        <ListItemText primary={test.name} />
                                    </ListItem>
                                )
                            })}
                        </List>
                        {item.tests.map(test => {
                            if (test.final_test === 1) {
                                return (
                                    <>
                                        <Divider />
                                        <ListItem button component="a" key={test.id} onClick={() => {
                                            if (test.submission) {
                                                history.push(`/submission/${test.id}`)
                                            }
                                        }}>
                                            <ListItemIcon>
                                                {test.submission ? <DoneAll color={"secondary"} /> : <Ballot />}
                                            </ListItemIcon>
                                            <ListItemText primary={test.name} secondary={test.submission ? `${test.submission.score}/${test.submission.total_questions}` : null} />
                                        </ListItem>
                                    </>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default StudentSubject
