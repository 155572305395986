import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import makeRequest from '../../utils/makeRequest';
export const getTests = createAsyncThunk(
    'test/getAll',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/tests')
        return response.data
    }
)
export const addTest = createAsyncThunk(
    'test/addTest',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post('/tests', data.body)
        return response.data
    }
)
export const updateTest = createAsyncThunk(
    'test/updateTest',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).put('/tests', data.body)
        return response.data
    }
)
export const deleteTest = createAsyncThunk(
    'test/deleteTest',
    async (data, thunkAPI) => {
        await makeRequest(data.token, null, thunkAPI).delete(`/tests/${data.id}`)
        return data.id
    }
)
export const uploadQuestions = createAsyncThunk(
    'test/uploadQuestions',
    async(data,thunkAPI)=>{
        await makeRequest(data.token,'multipart/form-data',thunkAPI).post(`/tests/questions`,data.body)
        return
    }
)
// export const downloadTest = createAsyncThunk(
//     'test/download',
//     async(data,thunkAPI)=>{
//         const response = await makeRequest(data.token,null,thunkAPI).post('/downloadExcel',data.body)
//         return response.data
//     }
// )
const testSlice = createSlice({
    name:'test',
    initialState:{
        data:[],
        loading:false
    },
    reducers:{

    },
    extraReducers:{
        [getTests.fulfilled]: (state, action) => {
            state.data = action.payload.data
            state.loading = false
        },
        [getTests.pending]: (state, action) => {
            state.loading = true
        },
        [getTests.rejected]: (state, action) => {
            state.loading = false
        },
        [addTest.fulfilled]: (state, action) => {
            state.data.push(action.payload.data)
            state.loading = false
        },
        [addTest.pending]: (state, action) => {
            state.loading = true
        },
        [addTest.rejected]: (state, action) => {
            state.loading = false
        },
        [updateTest.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.id === action.payload.data.id) {
                    return { ...item, ...action.payload.data}
                }
                return item
            })
            state.loading = false
        },
        [updateTest.pending]: (state, action) => {
            state.loading = true
        },
        [updateTest.rejected]: (state, action) => {
            state.loading = false
        },
        [deleteTest.fulfilled]: (state, action) => {
            state.data = state.data.filter(x => x.id !== action.payload)
            state.loading = false
        },
        [deleteTest.pending]: (state, action) => {
            state.loading = true
        },
        [deleteTest.rejected]: (state, action) => {
            state.loading = false
        },
        [uploadQuestions.fulfilled]: (state, action) => {
            // state.data = state.data.filter(x => x.id !== action.payload)
            state.loading = false
        },
        [uploadQuestions.pending]: (state, action) => {
            state.loading = true
        },
        [uploadQuestions.rejected]: (state, action) => {
            state.loading = false
        },

    }
})
export default testSlice.reducer