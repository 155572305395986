import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from './Components/DataTable'
import { addSubject, deleteSubject, getSubjects, updateSubject } from './Redux/subjectSlice'
function Subjects() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const subject = useSelector(state => state.subject)
    const [data, setData] = useState([])
    useEffect(() => {
        dispatch(getSubjects({ token: auth.token }))
        // eslint-disable-next-line
    }, [])
    const columns = [
        { title: 'ID', field: 'id', editable: "never" },
        { title: 'Name', field: 'name' },
        { title: "Grade", field: "grade" }
    ]
    useEffect(() => {
        setData(JSON.parse(JSON.stringify(subject.data)))
    }, [subject.data])
    return (
        <DataTable
            title={"Subjects"}
            columns={columns}
            loading={subject.loading}
            data={data}
            onAdd={newData => dispatch(addSubject({
                token: auth.token,
                body: newData
            }))}
            onUpdate={(newData, oldData) => dispatch(updateSubject({
                token: auth.token,
                body: newData
            }))}
            onDelete={(oldData) => dispatch(deleteSubject({
                token: auth.token,
                id: oldData.id
            }))}
        />
    )
}

export default Subjects
