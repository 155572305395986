import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from './Redux/dashboardSlice';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { LocalLibrary, Person, ListAlt } from '@material-ui/icons';
function Dashboard() {
    const auth = useSelector(state => state.auth)
    const dashboard = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDashboardData({ token: auth.token }))
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <h1>Dashboard</h1>
            {
                dashboard.loading ?
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                    :
                    <>
                        <Grid container spacing={4} justifyContent='center'>
                            <Grid item xs={3}>
                                <Card className='gradient1' elevation={8}>
                                    <CardContent>
                                        <div className='statContainer'>
                                            <Person className='statLogo' htmlColor='white' />
                                            <div className='statLabelContainer'>
                                                <span className='statLabel'>Students</span>
                                                <span className='stat'>
                                                    {dashboard.data?.total_students}
                                                </span>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card className='gradient2' elevation={8}>
                                    <CardContent>
                                        <div className='statContainer'>
                                            <ListAlt className='statLogo' htmlColor='white' />
                                            <div className='statLabelContainer'>
                                                <span className='statLabel'>Tests Submitted</span>
                                                <span className='stat'>
                                                    {dashboard.data?.total_submissions}
                                                </span>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card className='gradient3' elevation={8}>
                                    <CardContent>
                                        <div className='statContainer'>
                                            <LocalLibrary className='statLogo' htmlColor='white' />
                                            <div className='statLabelContainer'>
                                                <span className='statLabel'>No. of Tests</span>
                                                <span className='stat'>
                                                    {dashboard.data?.total_tests}
                                                </span>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <div style={{ padding: 8 }} />
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Student Name</TableCell>
                                        <TableCell>Topic Name</TableCell>
                                        <TableCell>Test Name</TableCell>
                                        <TableCell>Score</TableCell>
                                        <TableCell>Date/Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dashboard.data?.past_records?.map(row => (
                                        <TableRow key={row.created_at}>
                                            <TableCell component="th" scope="row">
                                                {row.user_name}
                                            </TableCell>
                                            <TableCell>{row.topic_name}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{`${row.score} / ${row.total_questions}`}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
            }

        </div>
    )
}

export default Dashboard
