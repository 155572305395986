import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from './Components/DataTable'
import { addBatch, deleteBatch, getBatches, updateBatch } from './Redux/batchSlice'
function Batches() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const batch = useSelector(state => state.batch)
    const [data, setData] = useState([])
    useEffect(() => {
        dispatch(getBatches({ token: auth.token }))
        // eslint-disable-next-line
    }, [])
    const columns = [
        { title: 'ID', field: 'id', editable: "never" },
        { title: 'Name', field: 'name' },
    ]
    useEffect(() => {
        setData(JSON.parse(JSON.stringify(batch.data)))
    }, [batch.data])
    return (
        <DataTable
            title={"Batches"}
            columns={columns}
            loading={batch.loading}
            data={data}
            onAdd={newData => dispatch(addBatch({
                token: auth.token,
                body: newData
            }))}
            onUpdate={(newData, oldData) => dispatch(updateBatch({
                token: auth.token,
                body: newData
            }))}
            onDelete={(oldData) => dispatch(deleteBatch({
                token: auth.token,
                id: oldData.id
            }))}
            
        />
    )
}

export default Batches
