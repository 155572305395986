import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { login } from './Redux/authSlice'
const useStyles = makeStyles({
    root: {
        minWidth: 320,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
});
function Login() {
    const classes = useStyles();
    const username = useRef()
    const password = useRef()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const history = useHistory();
    useEffect(() => {
        if (auth.token) {
            history.push("/dashboard")
        }
        // eslint-disable-next-line
    }, [auth.token])
    return (
        <div style={{ backgroundImage: `url(${require('../assets/login-bg.webp')})`, height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Card className={classes.root}>
                {auth.loading ? <LinearProgress color="secondary" /> : null}
                <CardContent className={classes.content}>
                    <Box mb={2}>
                        <Typography gutterBottom={true} variant="h6" align="center">Login</Typography>
                    </Box>
                    <Box my={1}>
                        <TextField label="Username" variant="standard" inputRef={username} size="small"/>
                    </Box>
                    <Box my={1}>
                        <TextField label="Password" variant="standard" type="password" inputRef={password} size="small" />
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={() => {
                            let data = {
                                username: username.current.value,
                                password: password.current.value
                            }
                            dispatch(login(data))
                        }}>Submit</Button>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

export default Login
