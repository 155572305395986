import React, { useEffect } from 'react';
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import BatchIcon from '@material-ui/icons/GroupWorkRounded';
import HomeIcon from '@material-ui/icons/HomeRounded'
import StudentsIcon from '@material-ui/icons/PeopleAltRounded'
import TopicIcon from '@material-ui/icons/MenuBookRounded';
import TestIcon from '@material-ui/icons/AssignmentRounded'
import SubjectIcon from '@material-ui/icons/ImportContactsRounded'
import TopicAssignIcon from '@material-ui/icons/AssignmentTurnedInRounded'
import LogoutIcon from '@material-ui/icons/ExitToAppRounded'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from './Dashboard';
import { Route, useHistory, useLocation } from 'react-router-dom';
import Students from './Students';
import Batches from './Batches';
import Topics from './Topics';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from './Components/CustomDialog';
import Tests from './Tests';
import { logout } from './Redux/authSlice';
import Subjects from './Subjects';
import TopicAssignment from './TopicAssignment';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import NewReleases from '@material-ui/icons/NewReleasesRounded'
import Registration from './Registration';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginLeft: theme.spacing(7) + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    bottomPush: {
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        paddingBottom: 10,
        width: drawerWidth
    },
    title: {
        fontFamily: "Lora, serif",
        textTransform: "uppercase",
        [theme.breakpoints.up('sm')]: {
            flexGrow: 1,
        },
    }
}));

function Main(props) {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const location = useLocation()
    const handleDrawerOpen = () => {
        setOpen(true)
    }
    const handleDrawerClose = () => {
        setOpen(false)
    }
    const history = useHistory()
    const changeRoute = (route) => {
        history.push(route)
    }
    useEffect(() => {
        if (auth.token === null) {
            history.replace('/')
        }
        // eslint-disable-next-line
    }, [auth.token])
    const drawer = (
        <div>
            <div className={classes.toolbar} >
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <List>
                <ListItem button key={"dashboard"} onClick={() => changeRoute('/dashboard')} selected={location.pathname === "/dashboard" ? true : false}>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button key={"batches"} onClick={() => changeRoute('/batches')} selected={location.pathname === "/batches" ? true : false}>
                    <ListItemIcon><BatchIcon /></ListItemIcon>
                    <ListItemText primary="Batches" />
                </ListItem>
                <ListItem button key={"subject"} onClick={() => changeRoute('/subjects')} selected={location.pathname === "/subjects" ? true : false}>
                    <ListItemIcon><SubjectIcon /></ListItemIcon>
                    <ListItemText primary="Subjects" />
                </ListItem>
                <ListItem button key={"topic"} onClick={() => changeRoute('/topics')} selected={location.pathname === "/topics" ? true : false}>
                    <ListItemIcon><TopicIcon /></ListItemIcon>
                    <ListItemText primary="Topics" />
                </ListItem>
                <ListItem button key={"tests"} onClick={() => changeRoute('/tests')} selected={location.pathname === "/tests" ? true : false}>
                    <ListItemIcon><TestIcon /></ListItemIcon>
                    <ListItemText primary="Tests" />
                </ListItem>
                <ListItem button key={"students"} onClick={() => changeRoute('/students')} selected={location.pathname === "/students" ? true : false}>
                    <ListItemIcon><StudentsIcon /></ListItemIcon>
                    <ListItemText primary="Students" />
                </ListItem>
                <ListItem button key={"topicAssignment"} onClick={() => changeRoute('/topicassignment')} selected={location.pathname === "/topicassignment" ? true : false}>
                    <ListItemIcon><TopicAssignIcon /></ListItemIcon>
                    <ListItemText primary="Topic Assignment" />
                </ListItem>
                <ListItem button key={"registration"} onClick={() => changeRoute('/registration')} selected={location.pathname === "/registration" ? true : false}>
                    <ListItemIcon><NewReleases /></ListItemIcon>
                    <ListItemText primary="Registration" />
                </ListItem>
            </List>
            <ListItem button key={"logout"} onClick={() => dispatch(logout())} style={{ position: "absolute", bottom: 0 }}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h4" noWrap className={classes.title} align="center">Select School Melbourne</Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                {drawer}
            </Drawer>
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}>
                <div className={classes.toolbar} />
                <CustomDialog />
                <Route path={`${props.match.path}dashboard`} exact={true} component={Dashboard} />
                <Route path={`${props.match.path}batches`} exact={true} component={Batches} />
                <Route path={`${props.match.path}subjects`} exact={true} component={Subjects} />
                <Route path={`${props.match.path}topics`} exact={true} component={Topics} />
                <Route path={`${props.match.path}tests`} exact={true} component={Tests} />
                <Route path={`${props.match.path}students`} exact={true} component={Students} />
                <Route path={`${props.match.path}topicassignment`} exact={true} component={TopicAssignment} />
                <Route path={`${props.match.path}registration`} exact={true} component={Registration} />
            </main>
        </div>
    );
}


export default Main;
