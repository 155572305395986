import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getTestData = createAsyncThunk(
    'testdetail/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get(`/test/view/${data.test_id}`)
        return response.data
    }
)

const testdetailSlice = createSlice({
    name: "testdetail",
    initialState: {
        loading: false,
        test_data: null,
    },
    reducers: {
    },
    extraReducers: {
        [getTestData.fulfilled]: (state, action) => {
            state.loading = false
            state.test_data = action.payload.data.test_data[0]
        },
        [getTestData.rejected]: (state, action) => {
            state.loading = false
        },
        [getTestData.pending]: (state, action) => {
            state.loading = true
        },
    }
})
export default testdetailSlice.reducer