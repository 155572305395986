import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';

export const getDashboardData = createAsyncThunk(
    'dashboard/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/')
        return response.data
    }
)

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        data: {},
        loading: false
    },
    extraReducers: {
        [getDashboardData.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload.data
        },
        [getDashboardData.pending]: (state, action) => {
            state.loading = true
        },
        [getDashboardData.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export default dashboardSlice.reducer