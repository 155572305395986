import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadQuestions } from "../Redux/testSlice";

function UploadQuestions({ rowData }) {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const auth = useSelector((state) => state.auth);
  const sendFile = () => {
    let data = new FormData();
    data.append("test_id", rowData.id);
    data.append("file", file);
    dispatch(
      uploadQuestions({
        token: auth.token,
        body: data,
      })
    );
  };
  return (
    <Box>
      <input
        label="Select XLSX"
        type="file"
        accept=".xlsx"
        onChange={(event) => setFile(event.target.files[0])}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            sendFile();
          }}
        >
          Upload
        </Button>
      </Box>
      <Box mt={2}>
        <a href={`${process.env.PUBLIC_URL}/Test.xlsx`} className="m-3">
          Download sample excel file
        </a>
      </Box>
    </Box>
  );
}

export default UploadQuestions;
