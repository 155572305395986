import { Button, CircularProgress } from "@material-ui/core";
import { PDFViewer } from "@react-pdf/renderer";
import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../utils/constants";
import PDFTest from "./Components/PDFTest";

const ViewPDF = (props) => {
  const [testDetails, setTestDetails] = useState();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    getTestData();
  }, []);
  const getTestData = async () => {
    const response = await Axios.get(
      `${SERVER_URL}/test/view/${props.match.params.id}`,
      {
        headers: {
          Authorization: auth.token,
          Accept: "application/json",
        },
      }
    );
    setTestDetails(response.data.data.test_data[0]);
  };
  return testDetails ? (
    <div style={{ width: "100vw", height: "100vh" }}>
      <PDFViewer height={"100%"} width={"100%"}>
        <PDFTest test_data={testDetails} />
      </PDFViewer>
    </div>
  ) : (
    <CircularProgress size={24} />
  );
};
export default ViewPDF;
