import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteStudent, getStudents, updateStudent } from './Redux/studentsSlice';
import { getBatches } from './Redux/batchSlice';
import { dialogOpen } from './Redux/dialogSlice';
import { ADD_STUDENT_DIALOG } from '../utils/constants';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import StudentDetails from './Components/StudentDetails';
import View from '@material-ui/icons/VisibilityRounded';
// import { useHistory } from 'react-router-dom';
function Students() {
    // const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const students = useSelector(state => state.students)
    const batch = useSelector(state => state.batch)
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([
        { title: 'Name', field: 'name' },
        { title: 'Username', field: 'username' },
        { title: 'Email', field: 'email' },
        { title: 'Contact', field: 'mobile_number', type: "numeric" },
        { title: 'Grade', field: 'grade', type: "numeric" },
        { title: 'Batch', field: 'batch_id', },
        { title: 'Active', field: 'active', type: "boolean" },
        { title: 'trial', field: 'trial', type: "boolean" },

    ])
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        View: forwardRef((props, ref) => <View {...props} ref={ref} />)
    };
    useEffect(() => {
        if (batch.data.length === 0) {
            dispatch(getBatches({ token: auth.token }))
            dispatch(getStudents({ token: auth.token }))
        } else {
            dispatch(getStudents({ token: auth.token }))
        }
        // eslint-disable-next-line  
    }, [])
    useEffect(() => {
        if (students.data.length > 0) {
            setData(JSON.parse(JSON.stringify(students.data)))
        }
    }, [students.data])
    useEffect(() => {
        if (batch.data.length !== 0) {
            let data = JSON.parse(JSON.stringify(batch.data))
            let lookup = {}
            data.forEach(element => {
                lookup[element.id] = element.name
            });
            let newColumns = columns.map(item => {
                if (item.field === "batch_id") {
                    return { ...item, lookup: lookup }
                }
                return item
            })
            setColumns(newColumns)
        }
        // eslint-disable-next-line  
    }, [batch.data])
    return (
        <MaterialTable
            icons={tableIcons}
            title={"Students"}
            columns={columns}
            data={data}
            loading={students.loading}
            actions={[
                {
                    icon: tableIcons.Add,
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                        dispatch(dialogOpen({
                            title: "Add Student",
                            component: ADD_STUDENT_DIALOG
                        }))
                    }
                },
                {
                    icon: tableIcons.View,
                    tooltip: "View",
                    onClick: (event, rowData) => {
                        window.open(`/student/${rowData.id}`, "_blank");
                        // history.push(`/student/${rowData.id}`)
                    }
                }
            ]}
            options={{
                addRowPosition: "first",
                actionsColumnIndex: -1,
                filtering: true,
                pageSize:25,
                pageSizeOptions:[25,50,75,100],
            }}
            editable={{
                onRowUpdate: (newData, oldData) => dispatch(updateStudent({
                    token: auth.token,
                    body: newData
                })),
                onRowDelete: (oldData) => dispatch(deleteStudent({
                    token: auth.token,
                    id: oldData.id
                }))
            }}
            detailPanel={rowData => <StudentDetails rowData={rowData} />}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
    )
}

export default Students
