import { addStudent } from "./studentsSlice";

const { createSlice } = require("@reduxjs/toolkit");

const dialogSlice = createSlice({
    name: "dialog",
    initialState: {
        open: false,
        component: null,
        title: null,
        data: null
    },
    reducers: {
        dialogOpen: (state, action) => {
            state.title = action.payload.title
            state.component = action.payload.component
            state.open = true
            state.data = action.payload.data
        },
        dialogClose: (state, action) => {
            state.title = null
            state.component = null
            state.open = false
            state.data = null
        }
    },
    extraReducers: {
        [addStudent.fulfilled]: (state, action) => {
            state.title = null
            state.component = null
            state.open = false
            state.data = null
        },
    }
})
export const { dialogOpen, dialogClose } = dialogSlice.actions
export default dialogSlice.reducer