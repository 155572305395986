import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';

export const getStudents = createAsyncThunk(
  'student/getAll',
  async (data, thunkAPI) => {
    const response = await makeRequest(data.token, null, thunkAPI).get('/students')
    return response.data
  }
)
export const addStudent = createAsyncThunk(
  'student/addBatch',
  async (data, thunkAPI) => {
    const response = await makeRequest(data.token, null, thunkAPI).post('/students', data.body)
    return response.data
  }
)
export const updateStudent = createAsyncThunk(
  'student/updateStudent',
  async (data, thunkAPI) => {
    const response = await makeRequest(data.token, null, thunkAPI).put('/students', data.body)
    return response.data
  }
)
export const deleteStudent = createAsyncThunk(
  'student/deleteStudent',
  async (data, thunkAPI) => {
    await makeRequest(data.token, null, thunkAPI).delete(`/students/${data.id}`)
    return data.id
  }
)
export const getStudentTopics = createAsyncThunk(
  'student/getStudentTopics',
  async (data, thunkAPI) => {
      const response = await makeRequest(data.token, null, thunkAPI).get(`/students/topics/${data.student_id}/${data.subject_id}`)
      return response.data
  }
)
export const syncStudentTopics = createAsyncThunk(
  'student/syncStudentTopics',
  async (data, thunkAPI) => {
      const response = await makeRequest(data.token, null, thunkAPI).post(`/students/topics`, data.body)
      return response.data
  }
)
const studentSlice = createSlice({
  name: 'student',
  initialState: {
    data: [],
    loading: false,
    subject_topics:[],
    user_topics:[]
  },
  reducers: {
    clearTopics:(state)=>{
      state.subject_topics = []
      state.user_topics = []
    }
  },
  extraReducers: {
    [addStudent.fulfilled]: (state, action) => {
      state.data.push(action.payload.data)
      state.loading = false
    },
    [addStudent.pending]: (state, action) => {
      state.loading = true
    },
    [addStudent.rejected]: (state, action) => {
      state.loading = false
    },
    [getStudents.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.loading = false
    },
    [getStudents.pending]: (state, action) => {
      state.loading = true
    },
    [getStudents.rejected]: (state, action) => {
      state.loading = false
    },
    [updateStudent.fulfilled]: (state, action) => {
      state.data = state.data.map(item => {
        if (item.id === action.payload.data.id) {
          return { ...item, ...action.payload.data }
        }
        return item
      })
      state.loading = false
    },
    [updateStudent.pending]: (state, action) => {
      state.loading = true
    },
    [updateStudent.rejected]: (state, action) => {
      state.loading = false
    },
    [deleteStudent.fulfilled]: (state, action) => {
      state.data = state.data.filter(x => x.id !== action.payload)
      state.loading = false
    },
    [deleteStudent.pending]: (state, action) => {
      state.loading = true
    },
    [deleteStudent.rejected]: (state, action) => {
      state.loading = false
    },
    [getStudentTopics.fulfilled]: (state, action) => {
      state.user_topics = action.payload.data.user_topics
      state.subject_topics = action.payload.data.topics
      state.loading = false
    },
    [getStudentTopics.pending]: (state, action) => {
      state.loading = true
    },
    [getStudentTopics.rejected]: (state, action) => {
      state.loading = false
    },
    [syncStudentTopics.fulfilled]: (state, action) => {
      state.user_topics = action.payload.data
      state.loading = false
    },
    [syncStudentTopics.pending]: (state, action) => {
      state.loading = true
    },
    [syncStudentTopics.rejected]: (state, action) => {
      state.loading = false
    },
  }
});
export const {clearTopics} = studentSlice.actions; 
export default studentSlice.reducer;
