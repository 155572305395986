import React, {  useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux'
import { updateStudent } from '../Redux/studentsSlice'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));
function StudentDetails({ rowData }) {
    const [data, setData] = useState(rowData)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }
    const classes = useStyles();
    return (
        <Box m={2} className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField label="Address" name="address" value={data.address} onChange={event => handleChange(event)} multiline rowsMax={4} variant="outlined" fullWidth size="small"/>
                </Grid>
                <Grid item>
                    <TextField label="Birth Date" name="birthdate" value={data.birthdate} onChange={event => handleChange(event)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small"/>
                </Grid>
                <Grid item>
                    <TextField label="School" name="school" value={data.school} onChange={event => handleChange(event)} variant="outlined" size="small"/>
                </Grid>
                {/* <Grid item>
                    <TextField label="Subjects" name="subjects" value={data.subjects} onChange={event => handleChange(event)} variant="outlined" />
                </Grid> */}
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField label="Password" name="password" onChange={event => handleChange(event)} type="password" variant="outlined" size="small"/>
                    </Grid>
                    <Grid item>
                        <TextField label="Confirm Password" name="password_confirmation" onChange={event => handleChange(event)} type="password" variant="outlined" size="small"/>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Button variant="contained" color="primary"
                    onClick={() => {
                        dispatch(updateStudent({
                            token: auth.token,
                            body: data
                        }))
                    }}>Save Changes</Button>
            </Box>
        </Box>
    )
}

export default StudentDetails
