import axios from 'axios';
import { enqueue } from '../app/Redux/notifySlice'
import { logout } from '../app/Redux/authSlice'
import { AUTHERROR, ERROR, SUCCESS, SERVER_URL } from './constants';

const makeRequest = (token, contentType, thunkAPI) => {
    let headers;
    if (contentType) {
        headers = {
            Authorization: token,
            Accept: "application/json",
            'content-type': contentType
        }
    } else {
        headers = {
            Authorization: token,
            Accept: "application/json",
        }
    }
    const Api = axios.create({
        baseURL: SERVER_URL,
        headers: headers
    });
    Api.interceptors.response.use(response => {
        if (response.data.status === SUCCESS) {
            if (response.data.msg !== "") {
                thunkAPI.dispatch(enqueue(
                    {
                        message: response.data.msg,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                        },
                    }
                ))
            }
            return response
        } else if (response.data.status === ERROR) {
            thunkAPI.dispatch(enqueue(
                {
                    message: response.data.msg,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                }
            ))
            let data = response.data.data
            for (let key in data) {
                thunkAPI.dispatch(enqueue(
                    {
                        message: data[key],
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                        },
                    }
                ))
            }
        } else if (response.data.status === AUTHERROR) {
            thunkAPI.dispatch(logout())
        }
    }, error => {
        thunkAPI.dispatch(enqueue(
            {
                message: "Please check your internet connection and try again",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                },
            }
        ))
    })
    return (Api)
}

export default makeRequest
