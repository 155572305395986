import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeRequest from '../../utils/makeRequest';

export const getSubjects = createAsyncThunk(
    'subject/getAll',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/subjects')
        return response.data
    }
)
export const addSubject = createAsyncThunk(
    'topic/addSubject',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post('/subjects', data.body)
        return response.data
    }
)
export const updateSubject = createAsyncThunk(
    'topic/updateSubject',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).put('/subjects', data.body)
        return response.data
    }
)
export const deleteSubject = createAsyncThunk(
    'topic/deleteSubject',
    async (data, thunkAPI) => {
        await makeRequest(data.token, null, thunkAPI).delete(`/subjects/${data.id}`)
        return data.id
    }
)
const subjectSlice = createSlice({
    name: 'subject',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {

    },
    extraReducers: {
        [getSubjects.fulfilled]: (state, action) => {
            state.data = action.payload.data
            state.loading = false
        },
        [getSubjects.pending]: (state, action) => {
            state.loading = true
        },
        [getSubjects.rejected]: (state, action) => {
            state.loading = false
        },
        [addSubject.fulfilled]: (state, action) => {
            state.data.push(action.payload.data)
            state.loading = false
        },
        [addSubject.pending]: (state, action) => {
            state.loading = true
        },
        [addSubject.rejected]: (state, action) => {
            state.loading = false
        },
        [updateSubject.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.id === action.payload.data.id) {
                    return { ...item, ...action.payload.data}
                }
                return item
            })
            state.loading = false
        },
        [updateSubject.pending]: (state, action) => {
            state.loading = true
        },
        [updateSubject.rejected]: (state, action) => {
            state.loading = false
        },
        [deleteSubject.fulfilled]: (state, action) => {
            state.data = state.data.filter(x => x.id !== action.payload)
            state.loading = false
        },
        [deleteSubject.pending]: (state, action) => {
            state.loading = true
        },
        [deleteSubject.rejected]: (state, action) => {
            state.loading = false
        },
    }
});
export default subjectSlice.reducer;
