import MaterialTable from 'material-table'
import React, { forwardRef } from 'react'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import View from '@material-ui/icons/VisibilityRounded';
import { useDispatch } from 'react-redux';
import { enqueue } from './Redux/notifySlice';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    View: forwardRef((props, ref) => <View {...props} ref={ref} />)

};
export default function Registration() {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        fetchData()
    }, [])
    const dispatch = useDispatch()
    async function fetchData() {
        const response = await fetch("https://regapi.pointcooktutoring.com/api/registration", { method: "GET" })
        const responseJson = await response.json()
        const data = responseJson.data
        setData(data)
    }
    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Grade", field: "grade" },
                    { title: "School", field: "school" },
                    { title: "Subjects", field: "subjects" },
                    { title: "Birthdate", field: "birthdate" },
                    { title: "Zoom ID", field: "zoomid" },
                    { title: "Parent Name", field: "parent_name" },
                    { title: "Mobile Number", field: "mobile_number" },
                    { title: "Email", field: "email" },
                    { title: "Remarks", field: "remarks" },
                    { title: "Reference", field: "reference" },
                    { title: "Share Allowed", field: "share_concent", type: "boolean" },
                    { title: "Module", field: "module" },
                    { title: "Date", field: "created_at", type: "datetime" }
                ]}
                editable={{
                    onRowUpdate: async (newData, oldData) => {
                        const response = await fetch("https://regapi.pointcooktutoring.com/api/updateRegistration", {
                            method: "POST",
                            body: JSON.stringify(newData),
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        })
                        const responseJson = await response.json()
                        if (responseJson.status_code === 200) {
                            let newD = [];
                            data.forEach(item=>{
                                if(item.id === newData.id){
                                    newD.push(newData)
                                }else{
                                    newD.push(item)
                                }
                            })
                            setData(newD)
                            dispatch(enqueue(
                                {
                                    message: responseJson.message,
                                    options: {
                                        key: new Date().getTime() + Math.random(),
                                        variant: 'success',
                                    },
                                }
                            ))
                        } else {
                            dispatch(enqueue(
                                {
                                    message: "Something went wrong.",
                                    options: {
                                        key: new Date().getTime() + Math.random(),
                                        variant: 'error',
                                    },
                                }
                            ))
                        }

                    }
                }}
                data={data}
                title="Registrations"
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    filtering: true,
                    exportAllData:true,
                    exportButton:true,
                }} />
        </div>
    )
}