import React, { forwardRef } from 'react'
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import View from '@material-ui/icons/VisibilityRounded';
import UploadQuestions from './UploadQuestions';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    View: forwardRef((props, ref) => <View {...props} ref={ref} />)

};

function DataTable({ columns, title, data, onAdd, onUpdate, onDelete, loading, details, component,actions }) {
    if (details) {
        return (
            <MaterialTable
                icons={tableIcons}
                title={title}
                columns={columns}
                data={data}
                isLoading={loading}
                options={{
                    addRowPosition: "first",
                    actionsColumnIndex: -1,
                    filtering: true,
                    pageSize:25,
                    pageSizeOptions:[25,50,75,100]
                }}
                actions={actions}
                editable={{
                    onRowAdd: (newData) => onAdd(newData),
                    onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
                    onRowDelete: (oldData) => onDelete(oldData),
                }}
                detailPanel={rowData => {
                    switch (component) {
                        case "test":
                            return (
                                <UploadQuestions rowData={rowData} />
                            )
                        default:
                            return (
                                <div>

                                </div>
                            )
                    }
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
        )
    } else {
        return (
            <MaterialTable
                icons={tableIcons}
                title={title}
                columns={columns}
                data={data}
                isLoading={loading}
                options={{
                    addRowPosition: "first",
                    actionsColumnIndex: -1,
                    filtering: true,
                    pageSize:25,
                    pageSizeOptions:[25,50,75,100]
                }}
                editable={{
                    onRowAdd: (newData) => onAdd(newData),
                    onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
                    onRowDelete: (oldData) => onDelete(oldData),
                }}
            />
        )
    }
}

export default DataTable
