import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
const styles = StyleSheet.create({
  body: {
    padding: "24px 36px",
  },
  title: {
    fontSize: 12,
  },
  cardHeader: {
    fontSize: 12,
  },
  m3: {
    margin: 12,
  },
  introImage: {
    objectFit: "scale-down",
  },
  questionImage: {
    objectFit: "scale-down",
    maxHeight:250,
    width:"auto"
  },
  optionImage: {
    height:30,
    width:"auto",
    objectFit:"contain"
  },
  cardContent: {
    padding: 16,
  },
  br: {
    padding: 4,
    width: "100%",
  },
  radio: {
    height: 16,
    width: 16,
    borderRadius: 8,
    border: "1px solid black",
  },
  question: {
    border: "1px solid black",
    padding: 8,
  },
  introText: {
    fontSize: 8,
    marginBottom: 12,
  },
});

const PDFTest = ({ test_data }) => {
  const url = "https://api.selectschoolmelbourne.com.au/test/";
  const [questions, setQuestions] = useState(null);
  let alphabets = [
    "A. ",
    "B. ",
    "C. ",
    "D. ",
    "E. ",
    "F. ",
    "G. ",
    "H. ",
    "I. ",
    "J. ",
    "K. ",
    "L. ",
    "M. ",
    "N. ",
    "O. ",
    "P. ",
    "Q. ",
    "R. ",
    "S. ",
    "T. ",
    "U. ",
    "V. ",
    "W. ",
    "X. ",
    "Y. ",
    "Z.",
  ];
  useEffect(() => {
    if (test_data) {
      let q = [];
      let i = 1;
      test_data.questions.forEach((element) => {
        q.push(
          <View
            item
            key={element.id}
            wrap={false}
            style={{ margin: "8px 0px" }}
          >
            {element.intro_text != null && (
              <View style={styles.introText}>
                <Html
                  style={{ fontSize: 13 }}
                >{`<span>-</span> ${element.intro_text}`}</Html>
              </View>
            )}
            {element.intro_image != null && (
                <Image
                  style={{ ...styles.m3, ...styles.introImage }}
                  src={url + element.intro_image}
                />
            )}
            <View style={styles.question}>
              <Text style={styles.cardHeader}>{`Q${i}`}</Text>
              <View style={styles.cardContent}>
                {element.question != null && (
                  <View>
                    <Html style={{ fontSize: 12 }}>{element.question}</Html>
                  </View>
                )}
                {element.question_image != null && (
                  <Image
                    style={styles.questionImage}
                    source={url + element.question_image}
                  />
                )}
                <View style={styles.br} />
                {element.mcq === 1 ? (
                  JSON.parse(element.options).map((option, index) =>
                    option.type === "string" ? (
                      <Text style={{fontSize:10}}>
                        {alphabets.includes(option.value + ". ")
                          ? option.value
                          : alphabets[index]+" "+ option.value}
                      </Text>
                    ) : (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{fontSize:10}}>{alphabets[index]}</Text>
                        <Image
                          source={url + option.value}
                          style={styles.optionImage}
                        />
                      </View>
                    )
                  )
                ) : (
                  <View
                    style={{ border: "1px solid black", padding: 8 }}
                  ></View>
                )}
              </View>
            </View>
          </View>
        );
        i++;
      });
      setQuestions(q);
    }
    console.log(test_data);
    // eslint-disable-next-line
  }, [test_data]);
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={{ textAlign: "center", marginBottom: 24 }}>
          {test_data?.name}
        </Text>
        {questions}
      </Page>
    </Document>
  );
};

export default PDFTest;
